<script setup>
import { ref, onMounted, defineProps } from "vue";
const props = defineProps(['isDarkMode']);
const direction = ref()
const updateDirection = () => {
    direction.value = "ltr"; 
    document.querySelector("html").setAttribute('dir', direction.value);
    document.getElementsByClassName("navbar-nav")[0].style.display = "none"
    document.getElementsByClassName("two_links")[0].style.display = "none"

};
onMounted(updateDirection);
</script>

<template>

    <div :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }" class="privacy-policy">
    <h2 class="heading">Privacy Policy</h2>
    <p class="last-updated">Last Updated: September 11, 2024</p>

    <p class="intro-text">
        This Privacy Policy explains how we collect, use, and protect your information when you visit our website.
    </p>

    <h3 class="section-heading">1. Information We Collect</h3>
    <ul class="info-list">
        <li><strong>Personal Information</strong>: We may collect personal information such as your name, email address, and other contact details when you register on our site, subscribe to our newsletter, or engage with our content.</li>
        <li><strong>Non-Personal Information</strong>: We may collect non-personal information such as your IP address, browser type, and browsing behavior. This may include data collected through cookies, web beacons, and other tracking technologies.</li>
    </ul>

    <h3 class="section-heading">2. How We Use Your Information</h3>
    <ul class="info-list">
        <li>To personalize your experience on our website.</li>
        <li>To improve our website based on the information and feedback we receive from you.</li>
        <li>To serve relevant advertisements to you through Google Ads and other third-party services.</li>
    </ul>

    <h3 class="section-heading">3. Google Analytics and Google Ads</h3>
    <ul class="info-list">
        <li>We use Google Analytics to track and analyze website traffic, and Google Ads to display targeted advertisements. These services may collect information such as your IP address, browser type, and browsing behavior.</li>
        <li>Google may use cookies to serve ads based on your prior visits to our website or other websites.</li>
        <li>You can opt-out of Google’s use of cookies by visiting the <strong><a href="https://adssettings.google.com/" target="_blank" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">Google Ads Settings</a></strong> or using the <strong><a href="https://tools.google.com/dlpage/gaoptout" target="_blank" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">Google Analytics Opt-out Browser Add-on</a></strong>.</li>
    </ul>

    <h3 class="section-heading">4. How We Protect Your Information</h3>
    <p>We implement various security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>

    <h3 class="section-heading">5. Cookies</h3>
    <p>Our website uses cookies to enhance your experience. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser (if you allow) that enables the site’s systems to recognize your browser and capture and remember certain information.</p>

    <ul class="info-list">
        <li><strong>Types of Cookies Used</strong>: Session cookies, persistent cookies, and third-party cookies (including those from Google for advertising and analytics purposes).</li>
        <li><strong>Opt-Out</strong>: You can choose to disable cookies through your browser settings. However, this may affect your ability to use certain features of the site.</li>
    </ul>

    <h3 class="section-heading">6. Third-Party Disclosure</h3>
    <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law. We may share your information with trusted third parties who assist us in operating our website, conducting our business, or serving you, as long as those parties agree to keep this information confidential.</p>

    <h3 class="section-heading">7. Third-Party Links</h3>
    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

    <h3 class="section-heading">8. Changes to the Privacy Policy</h3>
    <p>We may update this Privacy Policy from time to time. Changes will be posted on this page, and we encourage you to review it regularly.</p>

    <h3 class="section-heading">9. Your Consent</h3>
    <p>By using our website, you consent to our Privacy Policy.</p>

    <h3 class="section-heading">10. Personal Data Rights</h3>
    <p>Depending on your location, you may have the right to access, correct, or delete the personal information we have collected about you. To exercise these rights, please contact us at <strong><a href="mailto:becuriousjourney@gmail.com" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">becuriousjourney@gmail.com</a></strong>.</p>

    <h3 class="section-heading">11. Contact Information</h3>
    <p>If you have any questions regarding this Privacy Policy, you can contact us at <strong><a href="mailto:becuriousjourney@gmail.com" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">becuriousjourney@gmail.com</a></strong> .</p>
</div>

</template>
<style>
/* General Styles for the Privacy Policy and Terms of Use */

.privacy-policy {
    width: 80%;
    margin: 20px auto;
    padding: 40px;
    border-radius: 8px;
    
}
.light-mode.privacy-policy {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.dark-mode.privacy-policy{
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.privacy-policy .heading {
    font-size: 2rem;
    margin-bottom: 10px;
}

.privacy-policy .last-updated {
    font-size: 0.9rem;
    margin-bottom: 20px;
}

.privacy-policy .intro-text {
    margin-bottom: 20px;
    font-size: 1rem;
}

.privacy-policy .section-heading {
    font-size: 1.5rem;
    margin-top: 20px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
}

.privacy-policy .info-list {
    list-style-type: disc;
    margin-left: 20px;
}

.privacy-policy .info-list li {
    margin-bottom: 10px;
}

.privacy-policy a {
    text-decoration: none;
}

.privacy-policy a:hover {
    text-decoration: underline;
}

.privacy-policy p {
    margin-bottom: 15px;
}

</style>